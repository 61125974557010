import React from 'react'

export default function Card({ children }) {
    return (
        <div style={{ overflowX: 'auto' }} className="card">
            <div className="card-content">
                {children}
            </div>
        </div>
    )
}
