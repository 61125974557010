import React, { useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { isEmpty } from 'lodash'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { measurements } from '../utils/types'
import { ImgSelectIcon } from './Svgs'
import { CATEGORY_LIST, domain } from '../urls'
import { useLoad } from '../hooks/request'
import { currencyOptions } from '../utils/position'

export default function ProductForm({
    onCancel,
    onSubmit,
    initialValues,
    loading,
}) {
    const [imageUrl, setImageUrl] = useState(null)
    const [image, setImage] = useState(null)
    let imageRef = useRef(null)

    function handleSubmit(data) {
        const newData = new FormData()
        if (image) {
            newData.append('image', image, image.name)
        }
        newData.append('name', data.name)
        newData.append('priceSale', data.priceSale)
        newData.append('price', data.price)
        newData.append('measurement', data.measurement)
        newData.append('count', data.count)
        newData.append('codeContent', data.codeContent)
        newData.append('category', data.category)
        onSubmit(newData)
    }
    const orders = useLoad({ url: CATEGORY_LIST })
    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={{
                name: '',
                priceSale: '',
                price: '',
                measurement: '',
                count: '',
                codeContent: '',
                category: '',
                currency: '',
                ...initialValues,
                image: initialValues && !isEmpty(initialValues.image) ? initialValues.image : '',

            }}>
            {({ setFieldValue }) => (
                <Form>

                    <div className="columns">
                        <div className={cn(css(styles.labelBox), 'column')} onClick={() => imageRef.click()}>
                            <div
                                style={{
                                    objectFit: 'cover',
                                    backgroundSize: 'cover',
                                    borderRadius: 10,
                                    width: 200,
                                    height: 300,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // eslint-disable-next-line no-nested-ternary
                                    backgroundImage: `url(${image ? imageUrl : (initialValues ? domain + initialValues.image : null)})`,
                                    backgroundColor: '#56575D',
                                }}
                            >
                                <input
                                    onChange={(e) => {
                                        setFieldValue('imageFile', e.target.files[0])
                                        setImage(e.target.files[0])
                                        setImageUrl(URL.createObjectURL(e.target.files[0]))
                                    }}
                                    style={{ display: 'none' }}
                                    accept="image/x-png,image/jpeg, image/heic"
                                    ref={(input) => {
                                        imageRef = input
                                    }}
                                    name="imageFile"
                                    type="file"
                                />
                                {(!image && !(initialValues && initialValues.image)) ? (
                                    <div
                                        className="is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
                                        <ImgSelectIcon className={cn('', css(styles.iconAccount))} />
                                        <p className="gilroyMd text-12 has-text-white mt-5">Add image</p>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className="column">
                            <Input
                                name="name"
                                label="Имя"
                                validate={required} />

                            <Select
                                empty
                                name="category"
                                label="Категории"
                                loading={orders.loading}
                                options={orders.response ? orders.response.results : []}
                            />

                            <Select
                                name="currency"
                                label="Валюта"
                                options={currencyOptions}
                            />

                            <Input
                                name="priceSale"
                                type="number"
                                label="Цена продажи"
                                validate={required} />
                            <Input
                                name="price"
                                type="number"
                                label="Цена покупки"
                                validate={required} />

                            <Input
                                name="count"
                                type="number"
                                label="Количество продуктов"
                                validate={required} />

                            <Select
                                empty
                                name="measurement"
                                label="Измерение"
                                optionValue="value"
                                optionLabel="name"
                                options={measurements}
                                validate={required} />

                            <Input
                                name="codeContent"
                                type="number"
                                label="Штрих-код"
                            />
                        </div>
                    </div>

                    <div className="is-pulled-right">
                        <Button
                            onClick={onCancel}
                            icon="close-outline"
                            text="Закрыть"
                            className="is-danger" />&nbsp;&nbsp;

                        <Button
                            loading={loading}
                            text="Сохранить"
                            icon="add-outline"
                            type="submit"
                            className="is-success" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const styles = StyleSheet.create({
    title: {
        fontFamily: 'GilroyBold',
        fontSize: '32px',
        color: '#1D1E22',
    },
    labelBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        margin: '3% 0',
    },
    iconAccount: {
        width: '24px',
        height: '24px',
        fontSize: 40,
    },
    errorImage: {
        color: 'red',
        textAlign: 'start',
        fontSize: '14px',
    },
    saveBtn: {
        width: 160,
        height: 54,
        background: 'linear-gradient(90deg, #7DBA28 0%, #9ACA3F 100%)',
        borderRadius: 5,
        color: '#fcfcfc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        fontFamily: 'GilroySemi',
        fontSize: 16,
        lineHeight: '18px',
    },
    cancelBtn: {
        width: 160,
        border: '1px solid #7DBA28',
        color: '#7DBA28',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fcfcfc',
        marginRight: 30,
        fontFamily: 'GilroySemi',
        fontSize: 16,
        lineHeight: '18px',
    },
    salesModelsLabel: {
        marginTop: '20px',
        marginLeft: '10px',
        fontFamily: 'GilroySemi',
        color: '#1D1E22',
    },
    checkboxGroup: {
        display: 'flex',
        gap: '40px',
        flexWrap: 'wrap',
        marginTop: '5px',
    },
    fieldGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '4%',
    },
    h3: {
        fontSize: '16px',
        fontFamily: 'GilroySemi',
        color: '#1D1E22',
    },
    fieldTitle: {
        fontFamily: 'Gilroy',
        color: '#1D1E22',
        margin: '2% 0 1% 0',
    },
    fieldMinus: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr auto',
    },
    minusRound: {
        width: 36,
        height: 36,
        borderRadius: '50%',
        background: 'rgba(255, 86, 48, 0.1)',
        display: 'grid',
        placeContent: 'center',
        alignSelf: 'center',
        marginLeft: '10px',
        marginTop: '15px',
        cursor: 'pointer',
    },
    minusSpan: {
        width: '13.5px',
        height: '1.5px',
        background: '#FF5630',
    },
    addBtn: {
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(125, 186, 40, 0.1)',
        borderRadius: '6px',
        border: 'none',
        padding: '7px 14px 7px 10px',
        color: '#7DBA28',
        fontFamily: 'GilroySemi',
        fontSize: '14px',
        cursor: 'pointer',
    },
    projectLabel: {
        fontSize: '14px',
        fontFamily: 'GilroyMd',
        color: '#1D1E22',
    },
    filesGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '15px',
        marginTop: '2%',
    },
    fileFlex: {
        display: 'flex',
        alignItems: 'center',
        gap: '40px',
        marginTop: '2%',
    },
    file: {
        display: 'flex',
        alignItems: 'center',
        gap: '2%',
    },
    fileIcon: {
        padding: '7px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
    },
    folderIcon: {
        background: 'rgba(131, 200, 5, 0.1)',
    },
    trashIcon: {
        background: '#FFEEEA',
        color: '#EA4335',
        cursor: 'pointer',
        fontFamily: 'GilroySemi',
        fontSize: '14px',
        height: '38px',
    },
    fileRight: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
    },
    fileName: {
        fontFamily: 'Gilroy',
        fontWeight: 600,
        fontSize: '14px',
        color: '#1D1E22',
        overflow: 'hidden',
    },
    fileSize: {
        fontFamily: 'Gilroy',
        fontWeight: 500,
        fontSize: '12px',
        color: '#454649',
        opacity: 0.5,
        textAlign: 'start',
    },
    videosGrid: {
        display: 'flex',
        gap: '20px',
        flexWrap: 'wrap',
    },
    videosGridChild: {
        position: 'relative',
        borderRadius: '14px',
    },
    videosGridChildImg: {
        width: '244px',
        height: '190px',
        objectFit: 'cover',
        borderRadius: '14px',
        filter: 'brightness(65%)',
    },
    videosGridChildPlayBtn: {
        width: 36,
        height: 36,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        borderRadius: '50%',
        background: '#7DBA28',
        backdropFilter: 'blur(3px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    videosGridChildClose: {
        position: 'absolute',
        top: -10,
        right: -10,
        cursor: 'pointer',
    },
    videosAddBtnDiv: {
        alignSelf: 'flex-end',
        marginLeft: '20px',
    },
    descriptionBtns: {
        display: 'flex',
        gap: '20px',
        justifyContent: 'flex-end',
        marginTop: '20px',
        marginBottom: '4%',
    },
    videosGroup: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: '20px',
        marginBottom: '20px',
    },
    videosLeft: {
        width: '60%',
    },
    or: {
        color: '#1D1E22',
        fontFamily: 'GilroyMd',
        opacity: 0.5,
        marginBottom: '13px',
    },
    uploadDiv: {
        width: '40%',
        borderRadius: '5px',
        border: '1px solid #7DBA28',
        background: '#F2F8E9',
        ':hover': {
            background: 'white',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    uploadLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        fontSize: '15px',
        fontWeight: '600',
        fontFamily: 'Gilroy',
        color: '#7DBA28',
        padding: '15px',
    },
    btnGroup: {
        display: 'flex',
        gap: '3%',
        justifyContent: 'flex-end',
        marginTop: '5%',
    },

})
