import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useLoad } from '../hooks/request'
import { ORDER_ARCHIVE_LIST } from '../urls'
import Layout from '../components/Layout'
import empty from '../static/empty-product.png'
import Table from '../components/common/Table'
import { useQueryParams } from '../hooks/queryString'
import ArchiveItem from '../components/ArchiveItem'
import { format } from '../utils/number'
import Search from '../components/Search'
import DateFilter from '../components/common/DateFilter'
// import { format } from '../utils/number'

export default function Archive() {
    const params = useQueryParams()
    const ordersList = useLoad({
        url: ORDER_ARCHIVE_LIST,
        params: {
            ...params,
        },
    })
    const orders = ordersList.response ? ordersList.response.results : []
    const totalForOrderHistoriesSum = ordersList.response ? ordersList.response.totalForOrderHistoriesSum : 0
    const totalForOrderHistoriesSumBuy = ordersList.response ? ordersList.response.totalForOrderHistoriesSumBuy : 0
    const total = totalForOrderHistoriesSum - totalForOrderHistoriesSumBuy

    return (
        <Layout>

            <div className="is-flex mb-3">
                <DateFilter className="mr-2" name="date" />
                <Search />

                <div
                    className="date_filter ml-2"
                    style={{ display: 'flex', gap: '1rem' }}
                >
                    <DateFilter name="startDate" />
                    <DateFilter name="endDate" />
                </div>

            </div>

            <div className="is-flex columns">
                <div className="column">
                    {orders.response && orders.response.count === 0 ? (
                        <div className="is-flex is-justify-content-center is-align-items-center hei">
                            <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                        </div>
                    ) : (

                        <Table
                            loading={ordersList.loading}
                            items={orders}
                            columns={{
                                name: 'Название',
                                price: 'Номер телефона',
                                ds: 'Итого',
                                registered_at: 'Создан в ',
                                actions: '',
                            }}
                            totalCount={ordersList.response ? ordersList.response.count : 0}
                            pageSize={15}
                            renderItem={(item) => (
                                <ArchiveItem
                                    ordersList={ordersList}
                                    key={item.id}
                                    item={item}

                                />
                            )} />
                    )}

                </div>

            </div>
            <hr />

            <div style={{ gap: 40 }} className="is-flex is-justify-content-left mb-2">
                <p className="is-size-5 tag is-link is-light">Общая сумма продаж:
                    <span className="">{format(totalForOrderHistoriesSum)}сум</span>
                </p>
                <p className="is-size-5 tag is-link is-light">
                    <span className="">
                        Общая сумма выгоде: {format(total)}сум
                    </span>
                </p>
            </div>

        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
            '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        height: 400,
    },
})
