import React from 'react'
import Layout from '../components/Layout'
import Table from '../components/common/Table'
import { useLoad } from '../hooks/request'
import { useQueryParams } from '../hooks/queryString'
import BonusTableItem from '../components/BonusTableItem'

export default function Bonus() {
    const params = useQueryParams()
    const clients = useLoad({
        url: '/main/bonus',
        params: {
            ...params,
        },
    })
    const clientList = clients.response ? clients.response.results : []
    return (
        <Layout>
            <Table
                loading={clients.loading}
                items={clientList}
                columns={{
                    index: '',
                    name: 'Имя',
                    address: 'Номер телефона',
                    price: 'Общая сумма чеки',
                }}
                totalCount={clients.response ? clients.response.count : 30}
                pageSize={15}
                renderItem={(item, index) => (
                    <BonusTableItem
                        index={index}
                        key={item.id}
                        item={item}
                    />
                )} />
        </Layout>
    )
}
