import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useGetRequest, useLoad, usePostRequest } from '../hooks/request'
import Layout from '../components/Layout'
import { ALL_SEND_MESSAGE,
    CLIENT_EXPORT,
    CLIENT_LIST,
    domain,
    PRODUCT_EXPORT,
    SEND_MESSAGE_SELECT,
    TOTAL_BORROW_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import { useQueryParams } from '../hooks/queryString'
import Table from '../components/common/Table'
import BorrowItem from '../components/BorrowItem'
import ClientCreate from '../components/ClientCreate'
import { useMessage } from '../hooks/message'
// import BorrowTabs from '../components/BorrowTabs'
import { format } from '../utils/number'
import Search from '../components/Search'
import Button from '../components/common/Button'
import DateFilter from '../components/common/DateFilter'

export default function Borrow() {
    const params = useQueryParams()
    const clients = useLoad({
        url: CLIENT_LIST,
        params: {
            ...params,
            type: 'active',
        },
    })
    const productList = clients.response ? clients.response.results : []

    const [showCreateModal, setShowCreateModal] = useModal(
        <ClientCreate
            clients={clients}
            onCancel={() => setShowCreateModal()}
        />,
    )

    const [checkedItems, setCheckedItems] = useState([])
    const handleCheckboxChange = (itemId, isChecked) => {
        setCheckedItems((prevState) => {
            if (isChecked) {
                return [...prevState, itemId]
            }
            return prevState.filter((id) => id !== itemId)
        })
    }
    const [showMessage] = useMessage()
    const send = usePostRequest({ url: SEND_MESSAGE_SELECT })

    async function onSend() {
        const { success } = await send.request({ data: { client_ids: checkedItems } })
        if (success) {
            showMessage('Сизнинг sms хабарингиз муваффақиятли юборилди', 'is-success')
            setCheckedItems([])
            clients.request()
        }
    }

    const total = useLoad({
        url: TOTAL_BORROW_LIST,
        params: { ...params },
    })

    const sendMessage = useGetRequest({ url: ALL_SEND_MESSAGE })

    async function onSendAll() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage('Сизнинг sms хабарингиз  муваффақиятли юборилди', 'is-success')
        }
    }

    const excel = useGetRequest({ url: CLIENT_EXPORT })
    const excelItem = excel.response ? excel.response.fileUrl || excel.response.file : null

    useEffect(() => {
        if (excelItem && !isEmpty(excelItem)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItem}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItem])
    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])

    return (
        <Layout onSend={onSend} showCreateModal={showCreateModal}>
            {/* <BorrowTabs /> */}

            <div className="is-flex">
                <Search />

                <DateFilter className="ml-2" name="date" />

                <Button
                    text="Выберите"
                    icon="send-outline"
                    className=" button is-link is-outlined ml-2"
                    onClick={onSend} />

                <Button
                    loading={sendMessage.loading}
                    onClick={onSendAll}
                    icon="paper-plane-outline"
                    className=" button is-link is-outlined ml-2"
                    text="Всем SMS" />

                <Button
                    onClick={excel.request}
                    icon="cloud-upload-outline"
                    className=" button is-link is-outlined ml-2"
                    text="Ехcел" />

                <Button
                    text="Добавить"
                    icon="add-outline"
                    className="button is-link is-outlined ml-2"
                    onClick={showCreateModal} />
            </div>
            <div className="pt-3 mb-3 is-pulled-right" style={{ textAlign: 'center', display: 'flex' }}>
                <span className="tag is-link is-light is-medium">
                    <h3 className="is-size-5 mr-4">Все Долги:</h3>
                    <p className="is-size-5"
                        style={{ fontWeight: '600' }}>
                        {format(total.response ? total.response.totalAmount : 0)} сум
                    </p>

                </span>
            </div>
            <div>

                <Table
                    loading={clients.loading}
                    items={productList}
                    columns={{
                        name: 'Имя',
                        address: 'Номер телефона',
                        price: 'Цена',
                        actisadons: 'Выберите',
                        actidssadons: 'Последней отправки',
                        action: '',
                    }}
                    totalCount={clients.response ? clients.response.count : 0}
                    pageSize={15}
                    renderItem={(item) => (
                        <BorrowItem
                            onCheckboxChange={handleCheckboxChange}
                            clients={clients}
                            products={clients}
                            key={item.id}
                            item={item}
                        />
                    )} />
            </div>
        </Layout>
    )
}
