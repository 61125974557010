import React from 'react'
import { format } from '../utils/number'

export default function BonusTableItem({ item, index }) {
    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                {item?.name}
            </td>
            <td>{item?.phoneNumber}</td>
            <td>{format(item.totalOrderSum ? item.totalOrderSum : '0')} Сум</td>
        </tr>
    )
}
