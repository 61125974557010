import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { PRODUCT_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import ProductCreate from '../components/ProductCreate'
import { useQueryParams } from '../hooks/queryString'
import empty from '../static/empty-shop.png'
import Table from '../components/common/Table'
import ProductItem from '../components/ProductItem'
import CheckCreate from '../components/CheckCreate'
import CheckItem from '../components/CheckItem'
import Loader from '../components/common/Loader'
import Search from '../components/Search'
import Button from '../components/common/Button'

export default function Home() {
    const params = useQueryParams()
    const products = useLoad({
        url: PRODUCT_LIST,
        params: { ...params, status: 'active', page_status: true },
    })
    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })
    const checkItem = check.response ? check.response.results : []
    const [showCreateModal, setShowCreateModal] = useModal(
        <ProductCreate
            reload={products}
            onCancel={() => setShowCreateModal()}
        />,
        styles.modal,
    )
    const [showCreateCheck, hideCreateCheck] = useModal(
        <CheckCreate
            reload={check}
            onCancel={() => hideCreateCheck()}
        />,
    )

    return (
        <Layout showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <div className="is-flex mb-3">
                <Search />

                <Button
                    text="Добавить"
                    icon="add-outline"
                    className="button is-link is-outlined ml-2 mr-2"
                    onClick={showCreateModal} />
                <Button
                    onClick={showCreateCheck}
                    icon="add-outline"
                    className=" button is-link is-outlined"
                    text=" Янги чек қўшиш" />
            </div>

            <div className="is-flex columns">
                <div className="is-8 column">
                    {products.response && products.response.count === 0 ? (
                        <div className="is-flex is-justify-content-center is-align-items-center hei">
                            <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                        </div>
                    ) : (
                        <div className="box">
                            <Table
                                loading={products.loading}
                                items={productList}
                                columns={{
                                    photo: 'Фото',
                                    name: 'Название',
                                    count: 'Количество',
                                    price: 'Цена',
                                    actions: '',
                                }}
                                totalCount={products.response ? products.response.count : 0}
                                pageSize={15}
                                renderItem={(item) => (
                                    <ProductItem
                                        products={products}
                                        check={check}
                                        key={item.id}
                                        item={item}

                                    />
                                )} />
                        </div>
                    )}
                </div>

                {!check.loading ? (
                    <div className="is-4 column">
                        {check.response && check.response.count === 0 ? (
                            <div className="is-flex is-justify-content-center is-align-items-center hei">
                                <img alt="empty" src={empty}
                                    className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                            </div>
                        ) : (
                            <div className="">
                                {checkItem.map((item) => (
                                    <CheckItem
                                        products={products}
                                        check={check}
                                        reload={check}
                                        key={item.id}
                                        item={item}
                                    />
                                ))}
                            </div>
                        )}
                        <div className=" is-flex is-justify-content-center" />
                    </div>
                ) : (
                    <Loader className={css(styles.loader)} large center />
                )}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
            '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        width: 1000,
    },

})
