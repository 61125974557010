import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
// import queryString from 'querystring'
import Layout from '../components/Layout'
import { useGetRequest, useLoad, usePostRequest } from '../hooks/request'
import { domain, PRODUCT_EXPORT, PRODUCT_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import ProductCreate from '../components/ProductCreate'
import { useQueryParams } from '../hooks/queryString'
import empty from '../static/empty-shop.png'
import Table from '../components/common/Table'
import CheckCreate from '../components/CheckCreate'
import ProductHomeItem from '../components/ProductHomeItem'
import { format } from '../utils/number'
import Search from '../components/Search'
import Button from '../components/common/Button'
import { useMessage } from '../hooks/message'
import DateFilter from '../components/common/DateFilter'

export default function Products() {
    const params = useQueryParams()
    const products = useLoad({
        url: PRODUCT_LIST,
        params: {
            ...params,
            status: 'active',
        },
    })
    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })

    const [showCreateModal, setShowCreateModal] = useModal(
        <ProductCreate
            reload={products}
            onCancel={() => setShowCreateModal()}
        />,
        styles.modal,
    )
    const [showCreateCheck, hideCreateCheck] = useModal(
        <CheckCreate
            reload={check}
            onCancel={() => hideCreateCheck()}
        />,
    )

    // const category = useLoad({
    //     url: CATEGORY_LIST,
    //     params: { status: 'active' },
    // })
    // const categoryItem = category.response ? category.response.results : []

    // async function setUrl(key, value) {
    //     const val = params[key] !== String(value) ? value : undefined
    //     history.push(`?${queryString.stringify({
    //         ...params,
    //         [key]: val,
    //     })}`)
    // }
    const history = useHistory()
    const [showMessage] = useMessage()

    const { request: postExcel } = usePostRequest({ url: '' })
    const handleProductImport = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            showMessage('Файл успешно отправлен', 'is-success')
            history.push('?')
        }
        if (error) {
            showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
        }
    }

    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])

    // function onSearch(query) {
    //     const search = !isEmpty(query) ? query : undefined
    //     history.push(`?${queryString.stringify({
    //         ...params,
    //         search,
    //     })}`)
    // }

    return (
        <Layout showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>

            <div className="is-flex mb-3">
                <Search />

                <div
                    className="date_filter ml-2"
                    style={{ display: 'flex', gap: '1rem' }}
                >
                    <DateFilter name="startDate" />
                    <DateFilter name="endDate" />
                </div>

                <Button
                    onClick={excelProduct.request}
                    icon="cloud-upload-outline"
                    className="button is-link is-outlined ml-2 mr-2"
                    text="Экспорт" />

                <label style={{ position: 'relative' }}>
                    <Button
                        icon="cloud-download-outline"
                        className="button is-link is-outlined ml-2 mr-2"
                        text="Импорт" />
                    {/* eslint-disable-next-line max-len */}
                    <input onChange={handleProductImport} value="" type="file" style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                    }} />
                </label>

                <Button
                    text="Добавить"
                    icon="add-outline"
                    className="button is-link is-outlined ml-2"
                    onClick={showCreateModal} />
                {/* <Button */}
                {/*    onClick={showCreateCheck} */}
                {/*    icon="add-outline" */}
                {/*    className=" button is-link is-outlined" */}
                {/*    text=" Янги чек қўшиш" /> */}
            </div>
            {/* <div className="mb-2">
                <span onClick={() => {
                    setUrl('category', '')
                    setUrl('season', '')
                }} className={cn(`tag is-medium mr-2 mb-2 ${(params.category === '' && params.season === '')
                    ? 'is-danger' : 'is-link'}`)}>
                    Все
                </span>
                {categoryItem.map((item) => (
                    <span onClick={() => setUrl('category', item.id)}
                        className={cn(`tag is-medium mr-2 mb-2 ${params.category !== String(item.id) ? 'is-link' : 'is-danger'}`)}>
                        {item.name}
                    </span>
                ))}
            </div> */}

            {/* <div className="mb-2">
                <span onClick={() => setUrl('season', 'winter')}
                    className={cn(`tag is-medium mr-2 mb-2 ${params.season === 'winter' ? 'is-success' : 'is-warning'}`)}>
                    Зимний
                </span>
                <span onClick={() => setUrl('season', 'summer')}
                    className={cn(`tag is-medium mr-2 mb-2 ${params.season === 'summer' ? 'is-success' : 'is-warning'}`)}>
                    Летний
                </span>
            </div> */}

            <div className="mt-4">
                {products.response && products.response.count === 0 ? (
                    <div className="is-flex is-justify-content-center is-align-items-center hei">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>
                ) : (

                    <Table
                        loading={products.loading}
                        items={productList}
                        columns={{
                            index: '',
                            photo: 'Фото',
                            name: 'Название',
                            category: 'Категории',
                            count: 'Количество',
                            priceSale: 'Цена продажи',
                            priceBuy: 'Цена покупки',
                            measurement: 'Измерение',
                            actions: '',
                        }}
                        totalCount={products.response ? products.response.count : 0}
                        pageSize={15}
                        renderItem={(item, index) => (
                            <ProductHomeItem
                                index={index}
                                products={products}
                                check={check}
                                key={item.id}
                                item={item}

                            />
                        )} />

                )}
            </div>

            <hr />

            <div style={{ gap: 40 }} className="is-flex is-justify-content-space-between mb-2">
                <span className="is-size-5 tag is-link is-light">Общая сумма покупки:
                    <span
                        className="">{format(products.response
                            ? products.response.totalSaleSum : 0)} сум
                    </span>
                </span>
                <p className="is-size-5 tag is-link is-light">Общая сумма продажи:
                    <span className="">{format(products.response
                        ? products.response.totalBuySum : 0)} сум
                    </span>
                </p>
                <p className="is-size-5 tag is-link is-light">Общая прибыль:
                    <span className="">
                        {
                            format((products.response ? products.response.totalSaleSum : 0)
                                - (products.response ? products.response.totalBuySum : 0))
                        } сум
                    </span>
                </p>
                <p className="is-size-5 tag is-link is-light">Все продуктов количестве:
                    <span className="">
                        {
                            (products.response ? products.response.totalProductsCount : 0)
                        }
                    </span>
                </p>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
            '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        width: 1000,
    },
    checkmark: {
        marginRight: '3px',
    },

})
