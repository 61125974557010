import React from 'react'
import { Form, Formik } from 'formik'
import Layout from '../../components/Layout/Layout'
import SettingsTabs from '../../components/settings/SettingsTabs'
import Card from '../../components/common/Card'
import Input from '../../components/common/Input'
import Button from '../../components/common/Button'
import { required } from '../../utils/validators'

export default function Currency() {
    const onSubmit = () => {}

    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                <Card>
                    <h3 style={{ lineHeight: '1.1' }} className="is-size-4 mb-4">Настройка валюты</h3>

                    <Formik initialValues={{ name: '' }} onSubmit={onSubmit}>
                        <Form>
                            <Input
                                label="Доллар курс"
                                name="name"
                                // disabled={loading}
                                placeholder="Введите "
                                validate={required}
                                type="number"
                            />

                            <div style={{ textAlign: 'end' }}>
                                <Button
                                    // loading={loading}
                                    text="Сохранить"
                                    type="submit"
                                    icon="checkmark-outline"
                                    className="is-success"
                                    // disabled={loading}
                                /> &nbsp;

                                {/* <Button
                                    // onClick={onCancel}
                                    icon="close-outline"
                                    text="Отмена"
                                    className="is-danger"
                                    // disabled={loading}
                                /> */}
                            </div>
                        </Form>
                    </Formik>
                </Card>
            </div>
        </Layout>
    )
}
