import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import debounce from 'lodash/debounce'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useQueryParams } from '../hooks/queryString'

export default function Search({ name = 'search', ...attributes }) {
    const history = useHistory()
    const queryParams = useQueryParams()
    const [searchValue, setSearchValue] = useState(queryParams[name] || '')
    const inputRef = useRef(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus() // always set focus on render
        }
    }) // No dependencies, this will run after every render

    const onChange = useCallback((({ target: { value } }) => {
        setSearchValue(value)
        const debouncedFunction = debounce(() => {
            const newParams = { ...queryParams }
            if (value.trim() !== '') {
                newParams[name] = value.trim()
            } else {
                delete newParams[name]
            }
            const queryStringified = queryString.stringify({ ...newParams, page: 1 })
            history.push(`?${queryStringified}`)
        }, 500)

        debouncedFunction()
    }), [history, name, queryParams])

    return (
        <div style={{ width: '100%' }}
            className="control has-icons-left is-expanded"
        >
            <input
                ref={inputRef}
                type="search"
                value={searchValue}
                onChange={onChange}
                className={cn('input')}
                placeholder="Поиск..."
                {...attributes}
            />
            <span className="icon is-small is-left">
                <ion-icon name="search" />
            </span>
        </div>
    )
}
